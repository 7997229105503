import React from 'react'
import { Badge, Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Icon from '../Icon'
import './about.css'

const formationsData = require('../../data/formation')
const savoirEtreData = require('../../data/savoir-etre')


const About = () => {

    //Hooks
    const navigate = useNavigate()

    const formationLink = (formation, data, place) => formation.link !== '' 
    ? <><a    href={formation.link} 
            target="_blank" 
            rel='noopener noreferrer'
            style={{color: 'white', textDecoration: 'underline'}}>
                {data}
        </a> {place}</>
    : <>{data} {place}</>

    const formationInfos = (formation) => formation.placeInformed 
    ? formationLink(formation, `${formation.awardedBy}`, `- ${formation.place}`)
    : formationLink(formation, `${formation.awardedBy}`)
    
    const formationAlternance = (formation) => formation.alternance
    ? <Card.Body className='py-2'>
        <div className='ms-5 ps-2'>
            <div className='d-flex align-items-center'>
                <span className='fw-bold'>{formation.dataAlternance.enterprise}</span>
                {formation.dataAlternance.tag ? <Badge bg='secondary' className='ms-2'>Alternance</Badge> : null}
            </div>
            <div>
                {formation.dataAlternance.poste}
            </div>
        </div>
    </Card.Body> 
    : <></>


    const formationMore = (formation) => formation.more
    ? <Card.Body className='py-2'>
        <div className='ms-5 ps-1 d-flex flex-wrap'>
            {formation.dataMore.map(more => {
                return <Icon key={more.id} iconName={more.name} iconLogoName={more.logo} tooltip={more.tooltip}/>
            })}
        </div>
    </Card.Body> 
    : <></>



    //Formations
    const formations = formationsData.map(
    formation => {
        return <ListGroup.Item key={formation.id}>
            <Card>
                <Card.Header className='d-flex align-items-start'>
                    <Badge bg='primary' pill style={{minWidth: '45px', marginTop: '1px'}}>
                        {formation.year}
                    </Badge>
                    <div >
                        
                        <div className='ms-2 me-auto'>
                            <div className='fw-bold'>{formation.name}</div>
                            <div>
                                {formationInfos(formation)}
                            </div>
                            
                        </div>
                    </div>
                </Card.Header>
                {formationAlternance(formation)}
                {formationMore(formation)}

            </Card>         
        </ListGroup.Item>
    })



    //Savoirs-Être
    const savoirsEtre = savoirEtreData.map(
        savoir => { return (
            <ListGroup.Item className='p-1 ps-2' id='data-list-savoir' key={savoir.id}>
                <div className='fw-bold mb-1'>{savoir.name}</div>
                <p className='mb-3'>{savoir.description}</p>
            </ListGroup.Item>
        )}
    )



    //render
    return (
        <Container  
            id='about' 
            data-scroll-data='1' 
            className='pb-4 section about-section'>

                <Row className='about-intro-box' style={{paddingTop: '80px', paddingBottom: '20px'}}>
                    <h2 className='mb-4'>À propos de moi</h2>
                </Row>

                <Row className='about-intro-box mb-2'>
                    
                    <div id='about-title'>
                        <p className='mb-0'>Je suis Michel Drault, Développeur web Full Stack.</p>  
                        <p>Un développeur web Full Stack sait faire aussi bien la partie Front-End que Back-End.</p> 

                        <div className='d-flex justify-content-start'>
                            <Card className='me-4'>Spécialisé en React.js pour la partie Front End.</Card>
                            <Card>Je travail en Java ou Node.js pour la partie Back End.</Card>
                        </div>      
                        <hr/>
                        <p>
                        Après 5 ans dans le secteur industriel à développer des interfaces utilisateur (homme-machine) 
                        et des logiciels internent entreprise, je me suis reconvertis dans le développement web afin de m'épanouir dans un domaine passionnant.
                        </p>
                        <p>
                            J'aime créer en permanence, découvrir de nouvelles technologies, découvrir de nouvelles façon de faire.
                        </p>
                    </div>
                </Row>

                <Row className='mb-5'>
                    <div>
                        <Button className='me-3' onClick={() => navigate('/cv')}>
                            Voir mon CV 
                        </Button>
                    </div>
                </Row>
    
                <Row>
                    <Col>
                        <h3>
                            Savoir être
                        </h3>
                        <ListGroup>
                            <ListGroup.Item id='data-list-savoir'>
                                <Card>
                                    <Card.Body>
                                        {savoirsEtre}
                                    </Card.Body>
                                </Card>                  
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>

                    <Col lg='8'>
                        <h3 className='ms-3'>
                            Parcours
                        </h3>
                        <ListGroup>{formations}</ListGroup>
                    </Col>
                </Row>

        </Container>
    )
}

export default About