import React from 'react'
import { Badge } from 'react-bootstrap'
import IconLogo from '../Icon/IconLogo'

const FormationLine = ({data, styleHeaderLeft, styleHeaderRight, styleFooterLeft, styleFooterRight, isYear}) => {

    const classNameYearActive = isYear ?
    <div className='d-flex justify-content-center formation-year' style={styleHeaderLeft || styleFooterLeft}>
        <Badge className='badge-cv align-self-center'>{data.year}</Badge>
    </div>
    : <div className='d-flex justify-content-center formation-year'/>


    const classMoreCol = data.moreCol 
    ? 'pt-1 pb-3 d-flex flex-wrap flex-column' 
    : 'pt-1 pb-3 d-flex flex-wrap'


    const displayMoreInfos = data.more 
    ? <div className='d-flex container-formation-line'>
        <div className='d-flex justify-content-center formation-year'/>
        <div className='formation-content flex-grow-1'>
            <span className={classMoreCol} >
                {data.dataMore.map(data => {
                    return <div className='me-2 d-flex align-items-center' key={data.id}>
                        <span className='me-2'><IconLogo iconLogoName={data.logo}/></span>
                        <span style={{fontSize: 10, paddingTop: 2}}>{' ' + data.name}</span>
                    </div>
                })}
            </span>
        </div>
        <div className='formation-end-content'/>
    </div>
    : data.alternance ?
        <div className='d-flex container-formation-line'>
            <div className='d-flex justify-content-center formation-year'/>
            <div className='formation-content flex-grow-1'>
                <span className='pt-1 pb-3 d-flex flex-wrap' >
                    <div className='me-2 d-flex align-items-center'>
                        <span className='me-2'>
                            <Badge bg='secondary' className='badge-cv align-self-center'>Alternance</Badge>
                        </span>
                        <span style={{fontSize: 10, paddingTop: 3}}  className='align-self-center'>
                            {data.dataAlternance.enterprise + ' : ' + data.dataAlternance.poste}
                        </span>
                    </div>
                </span>
            </div>
            <div className='formation-end-content'/>
        </div>
        : <div className='container-formation-line'/>


    const displayFormation = data.formation && (
        <Badge bg='info' pill className='p-1 me-1' style={{fontSize: 8}}>
            Formation
        </Badge>
    )

    const displaXpPro = data.xpPro && (
        <Badge bg='success' pill className='p-1 me-1 badge-pro' style={{fontSize: 8}}>
            {data.freelance ? 'Freelance' : 'Exp. Pro'}
        </Badge>
    )



    return (
        <>
            <div className='d-flex'>
                {classNameYearActive}
                <div className='d-flex formation-title-content flex-grow-1 align-items-center justify-content-between' style={styleHeaderRight || styleFooterRight}>
                    <span className='formation-title py-1'>{data.name}{data.placeInformed ? ' - ' + data.place : ''}</span>
                    {displayFormation}{displaXpPro}
                </div>
            </div>
            {displayMoreInfos}
        </>
        
    )
}

export default FormationLine
