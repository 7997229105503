import React from 'react'
import { Navbar, Container, Nav, OverlayTrigger, Tooltip, Image } from 'react-bootstrap'
import { FaHome, FaLinkedin, FaGithub } from 'react-icons/fa'
import { ImBooks } from 'react-icons/im'
import { FiMail } from 'react-icons/fi'
import { IoDocumentTextSharp } from 'react-icons/io5'
import maltIcon from '../../img/malt-logo.svg'
import './index.css'

const Header = () => {
  return (
      <Navbar className='fixed-top py-1' bg="light" variant="light" expand={'lg'}>
        <Container className='container-fluid'>
          <Nav className="me-auto">
          
            <Nav.Link href="#home" className='ps-0 me-4' style={{fontSize: '2.5vh'}}>
              <FaHome className='pb-1'/><span className='ps-2'>Accueil</span>
            </Nav.Link>

            <Nav.Link href="#project" className='me-4' style={{fontSize: '2.5vh'}}>
              <ImBooks className='pb-1'/><span className='ps-2'>Mes projets</span>
            </Nav.Link>

            <Nav.Link href="#about" className='me-4' style={{fontSize: '2.5vh'}}>
              <IoDocumentTextSharp className='pb-1'/><span className='ps-2'>CV</span>
            </Nav.Link>
            
          </Nav>
          <Nav>

            {/* Lien vers ma page linkedIn */}
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Linkedin
                </Tooltip>
              }
            >
              <Nav.Link 
                    target="_blank" 
                    rel='noopener noreferrer'
                    href='https://www.linkedin.com/in/michel-drault-60774b238/'
                    style={{fontSize: '2.5vh'}}>
                    <FaLinkedin/>
              </Nav.Link>
            </OverlayTrigger>
            
            {/* Lien vers ma page Github */}
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Github
                </Tooltip>
              }
            >
              <Nav.Link 
                    target="_blank" 
                    rel='noopener noreferrer'
                    href='https://github.com/MDapplications'
                    style={{fontSize: '2.5vh'}}>
                    <FaGithub/>
              </Nav.Link>
            </OverlayTrigger>

            {/* Lien vers ma page Malt */}
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Profil Malt
                </Tooltip>
              }
            >
              <Nav.Link 
                    target="_blank" 
                    rel='noopener noreferrer'
                    href='https://www.malt.fr/profile/micheld1'
                    style={{fontSize: '2.5vh'}}>
                    <Image id='icon' src={maltIcon}/>
              </Nav.Link>
            </OverlayTrigger>

            {/* Me contacter */}
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  Mon email michel.drault@gmail.com
                </Tooltip>
              }
            >
              <Nav.Link 
                    href='mailto:michel.drault@gmail.com'
                    style={{fontSize: '2.5vh'}}>
                    <FiMail/>
              </Nav.Link>
            </OverlayTrigger>
            

          </Nav>
        </Container>
      </Navbar>
  )
}

export default Header