import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CVprint from '../CVprint'
import Main from '../Main'
import './index.css'


const App = () => {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Main/>}/> 
            <Route path="/cv" element={<CVprint/>}/>
        </Routes>
    </Router>
  )
}

export default App