import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics"


const {
        REACT_APP_FIREBASE_API_KEY,
        REACT_APP_FIREBASE_AUTH_DOMAIN,
        REACT_APP_FIREBASE_PROJECT_ID,
        REACT_APP_FIREBASE_STORAGE_BUCKET,
        REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        REACT_APP_FIREBASE_APP_ID,
        REACT_APP_FIREBASE_MEASUREMENT_ID,
    } = process.env



// Your web app's Firebase configuration
const config = {
    apiKey: `${REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${REACT_APP_FIREBASE_MEASUREMENT_ID}`
}

class Firebase {

    //Constructeur
    constructor() {
        // Initialize Firebase
        this.app = initializeApp(config)
        this.analytics = getAnalytics(this.app)
        //this.auth = getAuth()
        //this.db = getFirestore(this.app)
    }
}

export default Firebase