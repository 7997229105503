import React from 'react'
import './icon.css'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import IconLogo from './IconLogo'

const Icon = ({iconLogoName, iconName, tooltip, activeTooltip, cName, cIcon}) => {

    

    const tooltipActivated = activeTooltip !== undefined ? false : activeTooltip
    const classnameName = '' + cName


    const componentIcon = <>
        <div className='item ms-0 me-1 mb-1 p-0' style={{backgroundColor: 'transparent'}}>
            <div className='icon flex-column'>
                <IconLogo iconLogoName={iconLogoName}/>
                <div className={`name ${classnameName}`} style={{textAlign: 'center'}}>
                    <span>{iconName}</span>
                </div>                    
            </div>
        </div>
    </>
    


    return tooltipActivated ?
    <OverlayTrigger
            placement='bottom'
            overlay={
            <Popover id="popover-contained">
                <Popover.Header as="h3"  className='fw-bold' style={{color: 'black'}}>{iconName}</Popover.Header>
                <Popover.Body>
                    {tooltip}
                </Popover.Body>
            </Popover>
            }
        >
            {componentIcon}
        </OverlayTrigger>

    : componentIcon
}

export default Icon