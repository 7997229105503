import React, { useState } from 'react'
import {Container, Row} from 'react-bootstrap'
import './index.css'

const Footer = () => {

    const [classCollapse, setClassCollapse] = useState('collapse')

    const spanMinSize = {fontSize: '0.75em'}  

    const handleMention = () => {
        const mention = document.getElementById('mention')
        mention.getAttribute('class') === 'collapse' 
        ? setClassCollapse('') 
        : setClassCollapse('collapse')
    }

    return (
        <footer className='footer text-white component-footer d-print-none'>
            <Container>
                <Row className='p-2 mb-3'>
                    <span>&copy; MDev 2022 - Portfolio Michel Drault</span> 
                </Row>
                <Row className='p-2'>

                    <button onClick={handleMention} className='d-flex mb-2 btn-mention'>
                        Mentions Légales
                    </button>

                    <span id='mention' style={spanMinSize} className={classCollapse}>
                        <p>
                            Conformément aux dispositions de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour 
                            la confiance dans l'économie numérique, nous vous informons que
                            le site internet portfolio.md-ev.ovh est édité par :</p>

                        <div className='col-sm-4 mb-3'>
                            <p className='mb-0'>Drault Michel (Auto-entreprise)</p>
                            <p className='mb-0'>SIRET: 91128202800016</p>
                            <p className='mb-0'>email: contact@md-ev.ovh</p>
                            <p>responsable de publication: Drault Michel</p>
                            <hr/>
                            <p className='mb-0'><i>Hébergement :</i></p>
                            <p>
                                Ce site est hébergé par Firebase, 188 King 
                                ST, San-Francisco, CA 94107, United States, 
                                https://firebase.google.com/
                            </p>
                        </div>
                    </span>
                    
                </Row>
            </Container>
            
        </footer>
    )
}

export default Footer