import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import './index.css'


const Projects = () => {
  return (
    <div id='container-project'>

      <Container  
        id='project' 
        data-scroll-data='1' 
        className='pb-4 section project-section'>

          <Row className='about-intro-box' style={{paddingTop: '80px', paddingBottom: '20px'}}>
              <h2>Mes projets</h2>
          </Row>

          <Row className='project-intro-box'>
              <p>
                  Voici quelques applications web que j'ai réalisé :
              </p>
          </Row>

          <Row>
              
            {/* Projet AGCV 2022*/}
            <Col className='d-flex align-items-stretch'>

              <Card>

                <Card.Header>
                  <Card.Title className='fw-bold mb-3'>Projet AGCV (2022)</Card.Title>
                  <Card.Subtitle className='text-muted fw-bold'>Migration en React.js et Node.JS du projet Java AGCV.</Card.Subtitle>
                </Card.Header>

                <Card.Img variant='top' src='https://i.ibb.co/gwTm5St/agcv-2022.png' className='px-2'/>
                
                <Card.Body>
                        
                    <p>
                      Le but étant de migrer l'application faite en Java vers une application React.JS (Front-End) et Node.JS (Back-End).
                    </p>
                    <p className='mb-0'>
                      L'application tournera sur le même raspberry.
                    </p>  
                    <p className='mb-5'>
                      Des nouveautés seront apportés à ce projet.
                      
                    </p>  
                          
                </Card.Body>

                <Card.Footer>
                    <Card.Link  href="https://github.com/MDapplications/agcv-abc" 
                                target="_blank" 
                                style={{color: 'white', textDecoration: 'underline'}}>
                                    Lien Github (Front-End)
                    </Card.Link>
                    <Card.Link  href="https://github.com/MDapplications/node-api-agcv"
                                target="_blank" 
                                style={{color: 'white', textDecoration: 'underline'}}>
                                    Lien Github (Back-End)
                    </Card.Link>
                  </Card.Footer>
                
              </Card>
            </Col>

            {/* Projet G2CB */}
            <Col className='d-flex align-items-stretch'>

              <Card>

                <Card.Header>
                  <Card.Title className='fw-bold mb-3'>Projet G2CB (2022)</Card.Title>
                  <Card.Subtitle className='text-muted fw-bold'>Gestion des Commandes d'un Club de Badminton</Card.Subtitle>
                </Card.Header>

                <Card.Img variant='top' src="https://i.ibb.co/DG8VsR3/G2-CB-Project.png" className='px-2'/>

                <Card.Body style={{paddingBottom: '55px'}}>

                    <p>
                      Cette application web faite entièrement en React.js est déployé/hébergé sur Google Firebase.
                    </p>
                    
                    <p className='mb-0'>
                      Intègre de l'authentification par email / mot de passe.
                    </p>
                    <p className='mb-0'>
                      Une Base de donnée noSQL Firestore (Base de donnée Firebase).
                    </p>
 
                </Card.Body>

                <Card.Footer>
                  <Card.Link  href="https://github.com/MDapplications/g2cb-abc" 
                              target="_blank" 
                              style={{color: 'white', textDecoration: 'underline'}}>
                                  Lien Github
                  </Card.Link>
                </Card.Footer>
              </Card>
              
            </Col>

            {/* Projet AGCV */}
            <Col className='d-flex align-items-stretch'>

              <Card>

                <Card.Header>
                  <Card.Title className='fw-bold mb-3'>Projet AGCV (2021)</Card.Title>
                  <Card.Subtitle className='text-muted fw-bold'>Application web de Gestion de la Consommation de Volants</Card.Subtitle>
                </Card.Header>

                <Card.Img variant='top' src="https://i.ibb.co/k3jsB2M/AGCV-Project.png" className='px-2'/>
                
                <Card.Body>
                        
                    <p className='mb-0'>
                      Cette application web fait en Java tourne actuellement sur un Raspberry Pi pour un accès local uniquement du client.
                    </p>
                    <p>
                      Le but n'était pas d'avoir une interface développé c'est pourquoi elle a été faite en HTML / CSS.
                    </p>                

                    <p className='mb-0'>
                      Le projet a été conçu avec le Framework Spring.
                    </p>
                    <p className='mb-0'>
                      Une base de donnée MariaDB est utilisé pour la persistance des données.
                    </p>
                          
                </Card.Body>

                <Card.Footer>
                    <Card.Link  href="https://github.com/MDapplications/AGCV-Project" 
                                target="_blank" 
                                style={{color: 'white', textDecoration: 'underline'}}>
                                    Lien Github
                    </Card.Link>
                    <Card.Link  href="https://imgur.com/a/jwIRFjp"target="_blank" 
                                style={{color: 'white', textDecoration: 'underline'}}>
                                    Capture d'écran du projet
                    </Card.Link>
                  </Card.Footer>
                
              </Card>
            </Col>

          </Row>

      </Container>
            
    </div>
  )
}

export default Projects