import React from 'react'
import FormationLine from './FormationLine'

const formationsData = require('../../data/formation-cv')

const Formation = () => {
  
    return (
        <div className='d-flex flex-column'>
            {
                formationsData.map(formation => {
                    if (formation.end) {
                        return <FormationLine 
                                    key={formation.id}
                                    data={formation} 
                                    styleHeaderLeft={{borderTopLeftRadius: 8}}
                                    styleHeaderRight={{borderTopRightRadius: 8}}
                                    isYear={formation.yearState}/>
                    } else if (formation.first) {
                        return <FormationLine 
                                    key={formation.id}
                                    data={formation} 
                                    styleFooterLeft={{borderBottomLeftRadius: 8}}
                                    styleFooterRight={{borderBottomRightRadius: 8}}
                                    isYear={formation.yearState}/>
                    } else {
                        return <FormationLine 
                                    key={formation.id}
                                    data={formation} 
                                    isYear={formation.yearState}/>
                    }    
                })
            }
        </div>
        
    )
}

export default Formation
