const savoirEtre = [
    {
        id: 1,
        name: "Autonomie",
        description: "J'aime travailler de manière indépendante dans la réalisation des projets que j'entreprends."
    },
    {
        id: 2,
        name: "Curieux",
        description: "Soif d'apprendre en permanence, j'aime comprendre les sujets sur lesquels je travaille."
    },
    {
        id: 3,
        name: "Travail en équipe",
        description: "Sur un projet commun, savoir se coordonner pour mener le projet à bien est important pour moi."
    },
    {
        id: 4,
        name: "Capacité d'adaptation",
        description: "Faire des projets de quelques jours, de plusieurs mois ou même faire des assistances techniques chez le client sont des choses que j'ai déjà gérées dans mon travail."
    },
    {
        id: 5,
        name: "Sens de l'organisation",
        description: "J'aime que les choses soient planifiées et organisées. Cela me permet de savoir où je dois aller et où j'en suis dans mon travail."
    }
]

module.exports = savoirEtre