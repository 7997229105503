import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ListGroup} from 'react-bootstrap'
import './cv-print.css'
import { FaGithub, FaLinkedin, FaMobileAlt } from 'react-icons/fa'
import { MdAlternateEmail, MdWeb } from 'react-icons/md'
import Formation from './Formation'

const savoirEtreData = require('../../data/savoir-etre')
const idDocCV = '1O5u9zl2MRZ5Zdm76pzAGHhqSn1CaRngr'


const CVprint = () => {

    //Hooks
    const navigate = useNavigate()

    //functions navigation
    const handleBack = () => {
        navigate(-1)
    }

    const handlePrint = () => {
        window.print()
    }

   
    //Savoirs-Être
    const savoirsEtre = savoirEtreData.map(
        savoir => {return (
            <ListGroup.Item className='p-1 ps-2 list-group-item-cv' key={savoir.id}>
                <div className='fw-bold mb-1 text-decoration-underline'>{savoir.name}</div>
                <p className='mb-1'>{savoir.description}</p>
            </ListGroup.Item>
        )})


    //render
    return (
        <div id='cv-print'>
            <div className='box wide hidden-on-narrow d-print-none d-flex'>
                <div className='box-col'/>
                <Button variant='primary' className='me-2' onClick={handleBack}>Retour</Button>
                <Button variant='success' className='me-2' onClick={handlePrint}>Imprimer</Button>
                <Button variant='success' 
                        href={`https://drive.google.com/uc?export=download&id=${idDocCV}`}>
                    Télécharger en PDF
                </Button>
                <div className='box-col' />
            </div>

            <div className='page-container hidden-on-narrow'>
                
                <div className='pdf-page size-a4-portrait portrait d-flex flex-column'>
                    

                    {/* HEADER */}
                    <div className='pdf-header d-flex justify-content-between align-content-center cv-title'>
                        <span>
                            <h1 className='ms-2 mb-2'>Michel Drault</h1>
                        </span>
                        <span>
                            <h1 className='mb-2'>Développeur Web Fullstack</h1>
                        </span>
                    </div>


                    {/* CONTACT ME */}
                    <div className='pdf-block'>
                        <div className='mx-2 pdf-element d-flex justify-content-between align-content-center'>
                            <div className='d-flex justify-content-start'>
                                <div className='coord px-1 d-flex flex-column justify-content-center'>
                                    <div style={{borderBottom: '0.5px solid #f1f9ff'}}><FaMobileAlt/> | 07.82.139.135</div>
                                    <div><MdAlternateEmail/> | michel.drault@gmail.com</div>
                                </div>
                                <div className='coord px-1 d-flex flex-column justify-content-center'>
                                    <div>56 rue des carrières</div>
                                    <div>60170 - Tracy le Mont</div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-column justify-content-center'>
                                    <div className='coord-link px-1'>
                                        <MdWeb/>{' '}
                                        <a 
                                            className='text-decoration-none' 
                                            href='https://portfolio.md-ev.ovh'
                                            target="_blank" 
                                            rel='noopener noreferrer'>
                                                portfolio.md-ev.ovh
                                        </a>
                                    </div>
                                    <div className='coord-link px-1'>
                                        <FaLinkedin/>{' '}
                                        <a 
                                            className='text-decoration-none' 
                                            href='http://linkedin.md-ev.ovh'
                                            target="_blank" 
                                            rel='noopener noreferrer'>
                                                linkedin.md-ev.ovh
                                        </a>
                                    </div>
                                </div>

                                <div className='d-flex flex-column justify-content-center'>
                                    <div className='coord-link px-1'>
                                        <FaGithub/>{' '}
                                        <a 
                                            className='text-decoration-none' 
                                            href='https://github.com/MDapplications'
                                            target="_blank" 
                                            rel='noopener noreferrer'>
                                            github.com/MDapplications
                                        </a>
                                    </div>
                                    <div className='coord-void px-1'/>
                                </div>
                            </div>
                        </div>
                        <div className='mx-2 mt-2 mb-2 pdf-element d-flex justify-content-start mobility'>
                            Souhait de mobilité sur l'agglomération Lilloise / Nord-Pas-de-Calais / Hauts-de-France.
                        </div>
                    </div>

                    {/* BODY */}
                    <div className='pdf-body flex-grow-1'>
                        <div className='d-flex'>
                            <div className='d-flex flex-column'>

                                {/* SAVOIR ETRE */}
                                <div className='savoir-etre-container ms-1 me-2'>
                                    <h3 className='ms-1 mb-1 h3-resize'>
                                        Savoir être
                                    </h3>
                                    <ListGroup id='savoir-etre-content'>
                                        {savoirsEtre}
                                    </ListGroup>
                                </div>

                                {/* HOBBIES */}
                                <div className='savoir-etre-container mt-4 ms-1 me-2'>
                                    <h3 className='ms-1 mb-1 h3-resize'>
                                        Passions
                                    </h3>
                                    <ListGroup id='savoir-etre-content'>
                                        <ListGroup.Item className='p-1 ps-2 list-group-item-cv'>
                                            <p className='mb-1'>Jeux d'échecs sur internet.</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='p-1 ps-2 list-group-item-cv'>
                                            <p className='mb-1'>Création de mix sur de la musique électronique.</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='p-1 ps-2 list-group-item-cv'>
                                            <p className='mb-1'>Astro-photographie & macro-photographie</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='p-1 ps-2 list-group-item-cv'>
                                            <p className='mb-1'>Administrateur d'une communauté francophone du jeu Factorio.</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='p-1 ps-2 list-group-item-cv'>
                                            <p className='mb-0'>Développeur de mod Factorio (contenu additionnel)</p>
                                            <p className='mb-1'>langage : Lua</p>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='p-1 ps-2 list-group-item-cv'>
                                            <p className='mb-0'>Partage de contenu en direct (streaming) :</p> 
                                            <li>Jeux-Vidéos</li>
                                            <li>Programmation</li>
                                            <li>Dessins, Lecture, ...</li>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                            
                            {/* PARCOURS */}
                            <div className='flex-grow-1'>
                                <h3 className='ms-1 mb-1 h3-resize'>
                                    Parcours
                                </h3>
                                <Formation/>
                            </div>
                        </div>


                        {/* <div className='d-flex flex-column mt-1'>
                            <h3 className='ms-1 mb-1 h3-resize flex-grow-1'>
                                Projets
                            </h3>
                            <div className='d-flex flex-grow-1'>
                                <div className='flex-grow-1 project-content-cv me-2 p-2'>
                                    <div className='ps-1'>Projet AGCV</div>
                                
                                </div>
                                <div className='flex-grow-1 project-content-cv p-2'>
                                    <div className='ps-1'>Projet G2CB</div>
                                </div>
                            </div>
                        </div> */}
                    </div>


                    <div className='pdf-footer text-center my-1'>
                        CV généré depuis mon site : <a href='https://portfolio.md-ev.ovh' 
                                                        className='text-decoration-none'
                                                        style={{color: 'black'}}>
                                                            https://portfolio.md-ev.ovh
                                                    </a>
                    </div>

                </div>
            </div>

            <div className='d-print-none' style={{paddingBottom: '45px'}}/>
        </div>
  )
}

export default CVprint




