import React from 'react'
import { FaJava, FaCss3Alt, FaHtml5, FaNpm, FaIndustry } from 'react-icons/fa'
import { IoLogoJavascript } from 'react-icons/io'
import { BsDisplay, BsAndroid2 } from 'react-icons/bs'
import { GiFishingHook } from 'react-icons/gi'
import {    SiSpring, 
            SiApachemaven, 
            SiHibernate, 
            SiGithub, 
            SiApachetomcat, 
            SiReact, 
            SiRedux, 
            SiFirebase, 
            SiNodedotjs, 
            SiDotnet } from 'react-icons/si'
import { AiOutlineConsoleSql, AiFillApi }  from 'react-icons/ai'

const IconLogo = ({iconLogoName}) => {
  
    const iconLogo = (logoName) => {
        switch (logoName) {
            case 'BsAndroid2' : return <BsAndroid2/>
            case 'SiDotnet': return <SiDotnet/>
            case 'FaJava': return <FaJava/>
            case 'FaCss3Alt': return <FaCss3Alt/>
            case 'FaHtml5': return <FaHtml5/>
            case 'IoLogoJavascript': return <IoLogoJavascript/>
            case 'SiSpring': return <SiSpring/>
            case 'SiApachemaven': return <SiApachemaven/>
            case 'SiHibernate': return <SiHibernate/>
            case 'AiOutlineConsoleSql': return <AiOutlineConsoleSql/>
            case 'SiGithub': return <SiGithub/>
            case 'SiApachetomcat': return <SiApachetomcat/>
            case 'SiReact': return <SiReact/>
            case 'SiRedux': return <SiRedux/>
            case 'SiFirebase': return <SiFirebase/>
            case 'GiFishingHook': return <GiFishingHook/>
            case 'FaNpm': return <FaNpm/>
            case 'SiNodedotjs': return <SiNodedotjs/>
            case 'AiFillApi': return <AiFillApi/>
            case 'BsDisplay': return <BsDisplay/>
            case 'FaIndustry': return <FaIndustry/>
            default: break;
        }
    }
  
    return iconLogo(iconLogoName)
}

export default IconLogo
