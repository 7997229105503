const formations = [
    {
        id: 12,
        name: "Développeur Java & Android : DSD System",
        end: true,
        first: false,
        year: "04/2023",
        xpPro: true,
        formation: false,
        freelance: false,
        yearState: true,
        placeInformed: true,
        place: "Lille (59)",
        alternance: false,
        dataAlternance: {},
        moreCol: true,
        more: true,
        dataMore: [
            {
                id: 0,
                name: "Développement sur l'application Android d'Altaïr.",
                logo: 'BsAndroid2'
            },
            {
                id: 1,
                name: "Développement en Java d'évolutions sur l'application Altaïr.",
                logo: 'FaJava'
            },
        ]
    },
    {
        id: 11,
        name: "Projet AGCV - FrontEnd React.js",
        end: false,
        first: false,
        year: 2022,
        xpPro: true,
        formation: false,
        freelance: true,
        yearState: true,
        placeInformed: false,
        place: "Freelance",
        alternance: false,
        dataAlternance: {},
        moreCol: true,
        more: true,
        dataMore: [
            {
                id: 0,
                name: "FrontEnd en React.js du projet AGCV (migration de l'application java de 2021).",
                logo: 'SiReact'
            },
            {
                id: 1,
                name: 'https://github.com/MDapplications/agcv-abc',
                logo: 'SiGithub'
            },
        ]
    },
    {
        id: 10,
        name: "Projet AGCV - BackEnd Node.js",
        end: false,
        first: false,
        year: 2022,
        xpPro: true,
        formation: false,
        freelance: true,
        yearState: false,
        placeInformed: false,
        place: "Freelance",
        alternance: false,
        dataAlternance: {},
        moreCol: true,
        more: true,
        dataMore: [
            {
                id: 0,
                name: "BackEnd en Node.js du projet AGCV.",
                logo: 'SiNodedotjs'
            },
            {
                id: 1,
                name: 'https://github.com/MDapplications/node-api-agcv',
                logo: 'SiGithub'
            }
        ]
    },
    {
        id: 9,
        name: "Certification Node.js",
        end: false,
        first: false,
        year: 2022,
        xpPro: false,
        formation: true,
        freelance: false,
        yearState: false,
        placeInformed: false,
        place: "",
        alternance: false,
        dataAlternance: {},
        moreCol: false,
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'Node.js',
                logo: 'SiNodedotjs',
            },
            {
                id: 1,
                name: 'npm',
                logo: 'FaNpm',
            },
            {
                id: 2,
                name: 'API Rest',
                logo: 'AiFillApi',
            }
        ]
    },
    {
        id: 8,
        name: "Projet G2CB : Gestion de Commandes d'un Club de Badminton",
        end: false,
        first: false,
        year: 2022,
        xpPro: true,
        formation: false,
        freelance: true,
        yearState: false,
        placeInformed: false,
        place: "Freelance",
        alternance: false,
        dataAlternance: {},
        moreCol: true,
        more: true,
        dataMore: [
            {
                id: 0,
                name: "Application en React.JS permettant la gestions d'articles (création de commandes, factures, bons de dépot).",
                logo: 'SiReact'
            },
            {
                id: 1,
                name: "Firebase utilisé pour le backend de l'application.",
                logo: 'SiFirebase'
            },
            {
                id: 2,
                name: 'https://github.com/MDapplications/g2cb-abc',
                logo: 'SiGithub'
            }
        ]
    },
    {
        id: 7,
        name: "Certification React.js",
        end: false,
        first: false,
        year: 2022,
        xpPro: false,
        formation: true,
        freelance: false,
        yearState: false,
        placeInformed: false,
        place: "",
        alternance: false,
        dataAlternance: {},
        moreCol: false,
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'React.js',
                logo: 'SiReact',
            },
            {
                id: 1,
                name: 'Hooks',
                logo: 'GiFishingHook',
            },
            {
                id: 2,
                name: 'Redux',
                logo: 'SiRedux',
            },
            {
                id: 3,
                name: 'Firebase',
                logo: 'SiFirebase'
            },
            {
                id: 4,
                name: 'npm',
                logo: 'FaNpm'
            },
        ]
    },
    {
        id: 6,
        name: "Projet AGCV - Java",
        end: false,
        first: false,
        year: 2021,
        xpPro: true,
        formation: false,
        freelance: false,
        yearState: true,
        placeInformed: false,
        place: "Freelance",
        alternance: false,
        dataAlternance: {},
        moreCol: true,
        more: true,
        dataMore: [
            {
                id: 0,
                name: "Application java de Gestion de la Consommation de Volants d'un club de Badminton.",
                logo: 'FaJava'
            },
            {
                id: 1,
                name: 'https://github.com/MDapplications/AGCV-Project',
                logo: 'SiGithub'
            },
        ]
    },
    {
        id: 5,
        name: "Certification Java JEE",
        end: false,
        first: false,
        year: 2020,
        xpPro: false,
        formation: true,
        freelance: false,
        yearState: true,
        placeInformed: true,
        place: "Cegefos Numérique Academie",
        alternance: false,
        dataAlternance: {},
        moreCol: false,
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'Java',
                logo: 'FaJava',
            },
            {
                id: 1,
                name: 'Spring',
                logo: 'SiSpring',
            },
            {
                id: 2,
                name: 'Maven',
                logo: 'SiApachemaven',
            },
            {
                id: 3,
                name: 'Hibernate',
                logo: 'SiHibernate',
            },
            {
                id: 4,
                name: 'Tomcat',
                logo: 'SiApachetomcat',
            },
            {
                id: 5,
                name: 'Html',
                logo: 'FaHtml5',
            },
            {
                id: 6,
                name: 'CSS',
                logo: 'FaCss3Alt',
            },
            {
                id: 7,
                name: 'JavaScript',
                logo: 'IoLogoJavascript',
            },
            {
                id: 8,
                name: 'SQL',
                logo : 'AiOutlineConsoleSql',
            },
            {
                id: 9,
                name: 'git',
                logo: 'SiGithub',
            }
        ]
    },
    {
        id: 4,
        end: false,
        first: false,
        name: "Technicien en Automatisme : Actemium",
        year: "2013 - 2019",
        xpPro: true,
        formation: false,
        freelance: false,
        yearState: true,
        placeInformed: true,
        place: "Clermont-Ferrand (63)",
        alternance: false,
        dataAlternance: {},
        moreCol: true,
        more: true,
        dataMore: [
            {
                id: 0,
                name: "Création de logiciel interne entreprise en VB.Net.",
                logo: 'SiDotnet',
            },
            {
                id: 1,
                name: "Interface utilisateur sur écran tactile ou PC de supervision de machine industrielle.",
                logo: 'BsDisplay',
            },
            {
                id: 2,
                name: "Programmation de machine industrielle automatisée.",
                logo: 'FaIndustry',
            }
        ]
    },
    {
        id: 3,
        end: false,
        first: false,
        name: "Licence Pro Automatisme Industriel",
        year: 2013,
        xpPro: false,
        formation: true,
        freelance: false,
        yearState: true,
        placeInformed: true,
        place: "Clermont-Ferrand (63)",
        alternance: true,
        dataAlternance: {
            enterprise: "Michelin",
            poste: "Bureau d'étude Calandrage Automatisme",
        },
        moreCol: false,
        more: false,
        dataMore: []
    },
    {
        id: 2,
        end: false,
        first: false,
        name: "DUT Génie Electrique et informatique Industrielle",
        year: 2011,
        xpPro: false,
        formation: true,
        freelance: false,
        yearState: true,
        placeInformed: true,
        place: "Soissons",
        alternance: false,
        dataAlternance: {},
        moreCol: false,
        more: false,
        dataMore: [
            {
                id: 0,
                name: 'VB.Net',
                logo: 'SiDotnet',
            }
        ]
    },
    {
        id: 1,
        end: false,
        first: true,
        name: "Bac STI Génie Electrotechnique",
        year: 2008,
        xpPro: false,
        formation: true,
        freelance: false,
        yearState: true,
        placeInformed: true,
        place: "Soissons (02)",
        alternance: false,
        dataAlternance: {},
        moreCol: false,
        more: false,
        dataMore: []
    }
]

module.exports = formations