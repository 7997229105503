import React from 'react'
import ReactTypingEffect from 'react-typing-effect'
import { bounce } from 'react-animations'
import { StyleSheet } from 'aphrodite'
import 'animate.css'
import './index.css'
import background from '../../img/bg.jpg'
import { Col, Container, Image, Row } from 'react-bootstrap'


//animation de rebond sur le prenom
const stylesTitle = StyleSheet.create({
    bounce: {
        animationName: bounce,
        animationDuration: '1s'
    }
})

//image de font de la section home
const section_background = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
}


const Home = () => {
    return (
        <div style={section_background}>
            <Container  
                id='home' 
                data-scroll-data='1' 
                className='section home-section home-intro-box container-fluid' 
                >

                    <Row className='d-flex flex-fill'>

                        <Col className='mx-5 d-flex text-center align-items-center pe-0'>
                            <Image id='photo-profil' src='https://i.ibb.co/y4zPCCr/moi-rond.png' roundedCircle fluid style={{border: 'none'}}/>
                        </Col>
                        
                        <Col id='home-d-main' className='m-5'>

                            <Row id='home-d-name'><b>Bonjour, Je suis</b></Row>
                            <Row>
                                <h1 className='animate__animated animate__bounce ps-1' style={stylesTitle}>
                                    Michel
                                </h1>

                                <h2 id='home-d-job' className='d-flex flex-wrap'>
                                    <span className='me-2'>
                                        Développeur web
                                    </span>
                                    
                                    <ReactTypingEffect 
                                        id='home-d-job-blue' 
                                        text={["fullstack", "front-end", "back-end"]}
                                        speed="100" 
                                        typingDelay="100" 
                                        eraseDelay="3000" />                            
                                </h2>
                            </Row>
                            
{/* 
                            <Row  className='my-2'>
                                <h3 id='home-d-freelance'>Freelance</h3>
                            </Row>

 */}
                            <Row id='home-freelance' className='p-4 mt-4'>

                                <p id='home-d-text' className='m-0'>
                                    Ayant envie de créer en permanence, je m'épanouis pleinement dans le domaine du web.
                                </p>

                                <p id='home-d-text' className='m-0 pt-3'>
                                    Spécialisé en React.js et le Java je dispose des atouts pour développer une application web complète.
                                </p>

                                <p id='home-d-text' className='m-0 pt-3'>
                                    Mon travail, répondre au mieux à vos besoins.
                                </p>

                            </Row>

                        </Col>

                    </Row>
            </Container>
        </div>
    )
}

export default Home
