import React from 'react'
import Footer from '../Footer'
import About from '../About'
import Projects from '../Projects'
import Header from '../Header'
import Home from '../Home'

const Main = () => {
    return (
        <>
            <Header/>

            <Home/> 
            <Projects/>
            <About/>
        
            <Footer/>
        </>
    )
}

export default Main
