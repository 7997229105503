const formations = [
    {
        id: 11,
        name: "Développeur Java & Android",
        year: "2023",
        awardedBy: "DSD System",
        link: "",
        placeInformed: true,
        place: "Lille (59)",
        alternance: true,
        dataAlternance: {
            tag: false,
            poste: "Actuellement en poste depuis début Avril.",
        },
        more: false,
        dataMore: []
    },
    {
        id: 10,
        name: "Projet AGCV - FrontEnd React.js / BackEnd Node.js",
        year: 2022,
        awardedBy: "Github du projet",
        link: 'https://github.com/MDapplications/agcv-abc',
        placeInformed: false,
        place: "",
        alternance: true,
        dataAlternance: {
            tag: false,
            poste: "Migration en React.js et Node.js de l'application faite en 2021.",
            enterprise: 'Freelance'
        },
        more: false,
        dataMore: [
            {
                id: 0,
                name: 'https://github.com/MDapplications/agcv-abc',
                logo: 'SiGithub'
            },
        ]
    },
    {
        id: 9,
        name: "Certificat Node.js - API Rest",
        year: 2022,
        awardedBy: "Udemy.com",
        link: 'https://www.udemy.com/',
        placeInformed: false,
        place: "",
        alternance: false,
        dataAlternance: {},
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'npm',
                logo: 'FaNpm',
                tooltip: `npm est le gestionnaire de paquets par défaut pour l'environnement d'exécution JavaScript Node.js de Node.js. npm se compose d'un client en ligne de commande, également appelé npm, et d'une base de données en ligne de paquets publics et privés payants, appelée le registre npm.`
            },
            {
                id: 1,
                name: 'Node.js',
                logo: 'SiNodedotjs',
                tooltip: `Node.js est une plateforme logicielle libre en JavaScript, orientée vers les applications réseau évènementielles hautement concurrentes qui doivent pouvoir monter en charge. Elle utilise la machine virtuelle V8 (le même moteur que Google Chrome).`
            },
            {
                id: 2,
                name: 'API Rest',
                logo: 'AiFillApi',
                tooltip: `API REST est un style d'architecture logicielle définissant un ensemble de contraintes à utiliser pour créer des services web. Les services web conformes au style d'architecture REST, établissent une interopérabilité entre les ordinateurs sur Internet.`
            }
        ]
    },
    {
        id: 8,
        name: "Projet G2CB - FrontEnd React.js / BackEnd sous Firebase",
        year: 2022,
        awardedBy: "Github du projet",
        link: 'https://github.com/MDapplications/g2cb-abc',
        placeInformed: false,
        place: "",
        alternance: true,
        dataAlternance: {
            tag: false,
            enterprise: "Freelance",
            poste: "Application en React.JS permettant la gestions d'articles (création de commandes, factures, bons de retour).",
        },
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'React.js',
                logo: 'SiReact',
                tooltip: `React est une bibliothèque JavaScript libre développée par Facebook depuis 2013. Le but principal de cette bibliothèque est de faciliter la création d'application web monopage, via la création de composants dépendant d'un état et générant une page HTML à chaque changement d'état.`
            },
            {
                id: 1,
                name: 'Hooks',
                logo: 'GiFishingHook',
                tooltip: `Les Hooks sont des fonctions qui permettent de « se brancher » sur la gestion d'état local et de cycle de vie de React depuis des fonctions composants.`
            },
            {
                id: 2,
                name: 'Redux',
                logo: 'SiRedux',
                tooltip: `Redux est une bibliothèque open-source JavaScript de gestion d'état pour applications web. Elle est plus couramment utilisée avec des bibliothèques comme React ou Angular pour la construction d'interfaces utilisateur.`
            },
            {
                id: 3,
                name: 'Firebase',
                logo: 'SiFirebase',
                tooltip: `Firebase est un ensemble de services d'hébergement pour n'importe quel type d'application. Il propose d'héberger en NoSQL et en temps réel des bases de données, du contenu, de l'authentification sociale, et des notifications, ou encore des services, tel que par exemple un serveur de communication temps réel.`
            },
            {
                id: 4,
                name: 'npm',
                logo: 'FaNpm',
                tooltip: `npm est le gestionnaire de paquets par défaut pour l'environnement d'exécution JavaScript Node.js de Node.js. npm se compose d'un client en ligne de commande, également appelé npm, et d'une base de données en ligne de paquets publics et privés payants, appelée le registre npm.`
            },
        ]
    },
    {
        id: 7,
        name: "Certificat React.js",
        year: 2022,
        awardedBy: "Udemy.com",
        link: 'https://www.udemy.com/',
        placeInformed: false,
        place: "",
        alternance: false,
        dataAlternance: {},
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'React.js',
                logo: 'SiReact',
                tooltip: `React est une bibliothèque JavaScript libre développée par Facebook depuis 2013. Le but principal de cette bibliothèque est de faciliter la création d'application web monopage, via la création de composants dépendant d'un état et générant une page HTML à chaque changement d'état.`
            },
            {
                id: 1,
                name: 'Hooks',
                logo: 'GiFishingHook',
                tooltip: `Les Hooks sont des fonctions qui permettent de « se brancher » sur la gestion d'état local et de cycle de vie de React depuis des fonctions composants.`
            },
            {
                id: 2,
                name: 'Redux',
                logo: 'SiRedux',
                tooltip: `Redux est une bibliothèque open-source JavaScript de gestion d'état pour applications web. Elle est plus couramment utilisée avec des bibliothèques comme React ou Angular pour la construction d'interfaces utilisateur.`
            },
            {
                id: 3,
                name: 'Firebase',
                logo: 'SiFirebase',
                tooltip: `Firebase est un ensemble de services d'hébergement pour n'importe quel type d'application. Il propose d'héberger en NoSQL et en temps réel des bases de données, du contenu, de l'authentification sociale, et des notifications, ou encore des services, tel que par exemple un serveur de communication temps réel.`
            },
            {
                id: 4,
                name: 'npm',
                logo: 'FaNpm',
                tooltip: `npm est le gestionnaire de paquets par défaut pour l'environnement d'exécution JavaScript Node.js de Node.js. npm se compose d'un client en ligne de commande, également appelé npm, et d'une base de données en ligne de paquets publics et privés payants, appelée le registre npm.`
            },
        ]
    },
    {
        id: 6,
        name: "Projet AGCV - Java / Spring Boot",
        year: 2021,
        awardedBy: "Freelance",
        link: '',
        placeInformed: false,
        place: "",
        alternance: false,
        dataAlternance: {},
        more: false,
        dataMore: [
            {
                id: 0,
                name: "FrontEnd en React.js du projet AGCV (migration de l'application java de 2021).",
                logo: 'SiReact'
            },
            {
                id: 1,
                name: 'https://github.com/MDapplications/agcv-abc',
                logo: 'SiGithub'
            },
        ]
    },
    {
        id: 5,
        name: "Certificat Java JEE",
        year: 2020,
        awardedBy: "Cegefos Numérique Academie",
        link: 'https://www.cegefos.com/formation-developpement-oriente-objet-java-ee/',
        placeInformed: false,
        place: "",
        alternance: false,
        dataAlternance: {},
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'Java',
                logo: 'FaJava',
                tooltip: `Java est un langage de programmation orienté objet, de Sun Microsystems. La société Sun a été ensuite rachetée en 2009 par la société Oracle qui détient et maintient désormais Java.`
            },
            {
                id: 1,
                name: 'Spring',
                logo: 'SiSpring',
                tooltip: `Spring est un framework qui fournit un modèle complet de programmation et de configuration pour les applications modernes basées sur Java sur tout type de plate-forme de déploiement.
                Un élément clé de Spring est la prise en charge de l'infrastructure au niveau de l'application : Spring se concentre sur la « plomberie » des applications afin de se concentrer sur la logique métier au niveau de l'application, sans liens inutiles avec des environnements de déploiement spécifiques.`
            },
            {
                id: 2,
                name: 'Maven',
                logo: 'SiApachemaven',
                tooltip: `Maven est un outil de gestion et d'automatisation de production des projets Java en général et Java EE en particulier. Il est utilisé pour automatiser l'intégration continue lors d'un développement de logiciel.`
            },
            {
                id: 3,
                name: 'Hibernate',
                logo: 'SiHibernate',
                tooltip: `Hibernate est un framework open source gérant la persistance des objets en base de données relationnelle.`
            },
            {
                id: 4,
                name: 'Tomcat',
                logo: 'SiApachetomcat',
                tooltip: `Tomcat est un serveur d'applications, plus précisément un conteneur web libre.`
            },
            {
                id: 5,
                name: 'Html',
                logo: 'FaHtml5',
                tooltip: `Le HTML5 spécifie deux syntaxes d'un modèle abstrait défini en termes de DOM : HTML5 et XHTML5. Le langage comprend également : une couche application avec de nombreuses API.`
            },
            {
                id: 6,
                name: 'CSS',
                logo: 'FaCss3Alt',
                tooltip: `Le CSS (Feuilles de style en cascade) est l'un des langages principaux du Web ouvert et a été standardisé par le W3C.`
            },
            {
                id: 7,
                name: 'JavaScript',
                logo: 'IoLogoJavascript',
                tooltip: `JavaScript est un langage de programmation de scripts principalement employé dans les pages web interactives et à ce titre est une partie essentielle des applications web. Avec les langages HTML et CSS, JavaScript est au cœur des langages utilisés par les développeurs web.`
            },
            {
                id: 8,
                name: 'SQL',
                logo : 'AiOutlineConsoleSql',
                tooltip: `SQL est un langage informatique normalisé servant à exploiter des bases de données relationnelles.`
            },
            {
                id: 9,
                name: 'git',
                logo: 'SiGithub',
                tooltip: `Git est un logiciel de gestion de versions décentralisé. Souvent associé à des plateformes telque Github.`
            }

        ]
    },
    {
        id: 4,
        name: "Technicien en Automatisme",
        year: "2013-2019",
        awardedBy: "Actemium",
        link: "",
        placeInformed: true,
        place: "Clermont-Ferrand (63)",
        alternance: false,
        dataAlternance: {},
        more: false,
        dataMore: []
    },
    {
        id: 3,
        name: "Licence Pro Automatisme Industriel",
        year: 2013,
        awardedBy: "Université Clermont Auvergne",
        link: "",
        placeInformed: true,
        place: "Clermont-Ferrand (63)",
        alternance: true,
        dataAlternance: {
            tag: true,
            enterprise: "Michelin",
            poste: "Bureau d'étude Calandrage Automatisme",
        },
        more: false,
        dataMore: []
    },
    {
        id: 2,
        name: "DUT Génie Electrique et informatique Industrielle",
        year: 2011,
        awardedBy: "IUT de l'Aisne",
        link: "",
        placeInformed: true,
        place: "Soissons (02)",
        alternance: false,
        dataAlternance: {},
        more: true,
        dataMore: [
            {
                id: 0,
                name: 'VB.Net',
                logo: 'SiDotnet',
                tooltip: `Visual Basic .NET est un langage de programmation à la syntaxe similaire à celle de VB6.
                VB.NET permet de développer en .NET via Visual Studio, sur les systèmes d'exploitation Windows.`
            }
        ]
    },
    {
        id: 1,
        name: "Bac STI Génie Electrotechnique",
        year: 2008,
        awardedBy: "Lycée Léonard de Vinci",
        link: "",
        placeInformed: true,
        place: "Soissons (02)",
        alternance: false,
        dataAlternance: {},
        more: false,
        dataMore: []
    }
]

module.exports = formations